@import url('https://fonts.googleapis.com/css2?family=PlayRegularfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Copse&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');

@font-face {
  font-family: "roboto_it";
  src: url("./assets/fonts/Roboto-Italic.woff2") 
      format("woff2"), 
      url("./assets/fonts/Roboto-Italic.woff")
      format("woff"),
      url("./assets/fonts/Roboto-Italic.ttf")
      format("ttf");
}

@font-face {
  font-family: "crimson_txt";
  src: url("./assets/fonts/CrimsonText-Regular.woff2") 
      format("woff2"), 
      url("./assets/fonts/CrimsonText-Regular.woff")
      format("woff"),
      url("./assets/fonts/CrimsonText-Regular.ttf")
      format("ttf");
}

@font-face {
  font-family: "playfair_disp";
  src: url("./assets/fonts/PlayfairDisplay-Regular.woff2") 
      format("woff2"), 
      url("./assets/fonts/PlayfairDisplay-Regular.woff")
      format("woff"),
      url("./assets/fonts/PlayfairDisplay-Regular.ttf")
      format("ttf");
}

@font-face {
  font-family: "sriracha_disp";
  src: url("./assets/fonts/Sriracha-Regular.woff2") 
      format("woff2"), 
      url("./assets/fonts/Sriracha-Regular.woff")
      format("woff"),
      url("./assets/fonts/Sriracha-Regular.ttf")
      format("ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
 }

 footer { margin-top: auto; }



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.form-heading{
  color: rgb(7, 116, 120);
  font-family: Georgia, 'Times New Roman', Times, serif ;
}

.form-labels{
  color: rgb(8, 99, 111) ;
  font-family: Helvetica, sans-serif;
}

.form-extras{
  color: rgb(11, 11, 11);
}


.extra-info{
  color: rgb(11, 11, 11);
  font-size: medium;
}

.tooltip-arrow{
  display: none !important
}

.page-heading{
  color: rgb(7, 116, 120);
  font-family: Georgia, 'Times New Roman', Times, serif ;
}

.twin-island-heading{
  color: rgb(110, 193, 93);
  font-family: 'roboto_it', Times, serif ;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
}

.twin-island2{
  color: rgb(24, 24, 23);
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.page-settings{
  background-color: rgb(55, 55, 165);
}

.section-heading{
  color: rgb(18, 6, 2);
  font-family: Verdana, Tahoma, sans-serif; 
  font-size: 35px;
  font-weight: bold;
}

.page-bg{
  background-color: rgb(8, 10, 30);
}

.questNav-color{
  background-color: rgb(59, 57, 46);
}

.row-width1{
  width: 20%;
}
.row-width2{
  width: 40%;
}
.row-width3{
  width: 40%;
}

.stock-header-img{
    display: flex;
    justify-content: center;
    
}

.stock-name{
  color: black;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 35px;
  font-weight: bold;
}

.stock-tagline{
  color: rgb(61, 27, 0);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif ;
  font-size: 20px;
}

.stock-desc{
  font-size: 16px;
  text-align: justify;
}

.stock-link{
  font-size: 30px;
}

.stock-heading{
  font-size: 40px;
  font-family: 'Times New Roman', Times, serif;
  color: rgb(122, 76, 76);
}

.stock-details-table{
  font-size: 20px;
}

.forgot-pw{
  font-size: 13px;
  color: rgb(48, 48, 29);
}

.summary-heading{
  font-size: 20px;
  color: #b75108;
}

.summary-main-heading{  
  font-family: 'crimson_txt', serif;
}

.quest-play-heading{
  font-size: 40px;
  font-family: 'playfair_disp', serif;
  
}

.quest-play-subheading1{
  font-family: 'roboto_it', sans-serif;
  font-size: 30px;
  color: rgb(5, 87, 137);
}

.quest-play-subheading2{
  font-family: 'roboto_it', sans-serif;
  font-size: 20px;
  color: rgb(2, 70, 87);
}

.quest-play-sectionheadings{
  font-family: 'roboto_it', sans-serif;
  font-size: 35px;
  color: rgb(83, 22, 5);
}

.quest-play-startdate{
  font-family: 'roboto_it', sans-serif;
  font-size: 16px;
}

.currency{  
  font-family: 'crimson_txt', serif;
}

.roboto-font{
  font-family: 'roboto_it', sans-serif;
}


.dropdown>a::after {
  color: #ffc107;
}

.inv-color{
  color: #1D97D4;
}

.quest-color{
  color: #F5B218;
}

.ttsec-blue-bg{
  background-color: #07549F;
}

.ttsec-blue{
  color: #07549F;
}

.admin-form-labels{
  color: rgb(12, 22, 46) ;
  font-family: Helvetica, sans-serif;
}

.admin-table-data{
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
}

.quest-join-label{
  font-family: 'roboto_it', sans-serif;
  color: rgb(66, 126, 174);
}

.graph-cash{
  color: #0088FE;
}

.graph-stock{
  color: #FF8C00;
}

.graph-mf{
  color: #556B2F;
}

.graph-bonds{
  color: #4B0082;
}

.trade-heading1{
  font-family: 'roboto_it', sans-serif;
  font-size: 35px;
  color: rgb(83, 22, 5);
}

.trade-heading2{
  font-family: 'roboto_it', sans-serif;
  font-size: 35px;
  color: rgb(7, 93, 104);
}

.trading-form-bg{
  background-color: rgb(59, 57, 46);
}

.home-header{
  background-color: rgb(74, 70, 70);
}

.home-headings{
  font-family: 'playfair_disp', sans-serif;
  font-size: 35px;
}

.home-quest-desc{
  color: #021c4e;
}

.justify-text{
  text-align: justify;
}

.trading-main-header{
  font-family: 'crimson_txt', serif;
  color: rgb(5, 24, 84);
}

.bonds-inv-c1{
  color: #340683;
}

.bonds-inv-c2{
  color: #066455;
}

.playfair-font{
  font-family: 'playfair_disp', serif;
}

.started-header{
  background-color: #1D97D4;
}

.broker-separator{
  background-color:#f5c908;
  color: #f5c908;
}



.starting-header{
  background-color: rgb(74, 70, 70);
}

.elite-color{
  color: #313230;
}

.quest_stock-heading1{
  font-family: 'roboto_it', sans-serif;
  font-size: 30px;
  color: rgb(83, 22, 5);
}

.homepage-header-bg{
  background-color: #075b8b;
}

.homepage-heading{
  font-size: 30px;
  color: rgb(66, 17, 17);
  font-family: 'crimson_txt', serif;
}

.homepage-heading2{
  font-size: 30px;
  font-family: 'crimson_txt', serif;
}

.homepage-twinIsland-heading{
  font-size: 30px;
  font-family: 'sriracha_disp', serif;
}

.homepage-separator-bg{
  background-color: rgb(74, 70, 70);
}

.getting-started-c1{
  color: #048872;
}

.twin-isl-heading{
  font-size: 50px;
  font-family: 'sriracha_disp', serif;
  color: #1a5613;
}

.sriracha-font{
  font-family: 'sriracha_disp', serif;
}

.quest-play-sectionheadings2{
  font-family: 'roboto_it', sans-serif;
  font-size: 35px;
  color: rgb(4, 69, 161);
}

.bg-alerts{
  background-color: rgb(10, 94, 94);
}

.bg-profile{
  background-color: rgb(121, 116, 69);
}

.quest-header-bg{
  background-color: #4682B4
}

.quest-end-bg{
  background-color: rgb(94, 92, 67);
}

.coming-soon-header-bg{
  background-color: rgb(234, 236, 226);
}

.res-heading{
  color: #266e19;
}

.home-heading-color{
  color: #35a004;
}

.home-about-color{
  color: #b75108;
}

.scale-react-player{
  height: auto !important;
  aspect-ratio: 16/9;
}


.banner {
  max-width: 1000px;
  display: table;
  margin: 0 auto;
}




